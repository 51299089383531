import React from 'react';
import './scroll-button.scss';

export interface Props {
    showScrollBtn: any
    cardsRef: React.Ref<HTMLDivElement>
}

const ScrollButton = (props: Props) => {

    const { showScrollBtn, cardsRef } = props;

    const scrollToTop = () => {
        // @ts-ignore
        cardsRef.current.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    return (
        showScrollBtn && (
            <button onClick={() => scrollToTop()} className={`scroll-button ${showScrollBtn ? 'scroll-button--show':'scroll-button--hide'}`}>
                <svg className="scroll-button__image" width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.4895 6.13675L7.62981 0.379365C7.46268 0.21515 7.2397 0.125 6.99719 0.125C6.75942 0.125 6.53658 0.21515 6.36945 0.379365L0.509611 6.13675C0.163463 6.47698 0.163463 7.03072 0.509611 7.3707L1.04164 7.8937C1.20877 8.05778 1.43175 8.14806 1.66964 8.14806C1.90741 8.14806 2.13804 8.05778 2.30504 7.8937L5.73114 4.53468V15.2062C5.73114 15.6872 6.11452 16.0795 6.60404 16.0795H7.35654C7.84605 16.0795 8.26824 15.6872 8.26824 15.2062V4.4968L11.7138 7.8937C11.8808 8.05778 12.0978 8.14806 12.3357 8.14806C12.5736 8.14806 12.7933 8.05778 12.9605 7.8937L13.491 7.3707C13.8372 7.03059 13.8359 6.47698 13.4895 6.13675Z" fill="white" />
                </svg>
            </button>
        )
    )
}

export default ScrollButton;
