import React from "react";
import { REACT_APP_CDN_SERVICE } from "env";
import "./index.scss";

const Logo = () => {
  return (
    <div className="logo">
      <a href="/" className="logo__link">
        <img src={`${REACT_APP_CDN_SERVICE}/logo.png`} width="64" alt="" />
      </a>
    </div>
  );
};

export default Logo;
