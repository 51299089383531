import React, { Dispatch, SetStateAction } from "react";
import "./index.scss";
import Card from "./card/index";

export interface Props {
  cards: CardInfo[];
  cardsRef: React.Ref<HTMLDivElement>;
  setShowScrollBtn: Dispatch<SetStateAction<boolean>>;
}

const Main = (props: Props) => {
  const { setShowScrollBtn, cardsRef, cards } = props;

  const onScroll = (evt: any) => {
    if (evt.currentTarget?.scrollTop > 100) {
      setShowScrollBtn(true);
    } else if (evt.currentTarget?.scrollTop === 0) {
      setShowScrollBtn(false);
    }
  };

  return (
    <div ref={cardsRef} onScroll={onScroll} className="cards cards-scroll">
      <ul className="cards__list">
        {cards.map((card) => (
          <Card key={card.id} card={card} />
        ))}
      </ul>
    </div>
  );
};

export default Main;
