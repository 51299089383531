import React from "react";
import { REACT_APP_CDN_SERVICE } from "env";
import "./index.scss";
import Button from "../../../../components/button/Button";
import ImagePlaceholder from "../../../../components/image-placeholder/ImagePlaceholder";

type Props = {
  card: CardInfo;
};

const Card = (props: Props) => {
  const { title, description, links, image } = props.card;

  const [isLoadedThumb, setLoadedThumb] = React.useState(false);

  const onLoad = () => setLoadedThumb(true);

  return (
    <li className="cards__item">
      <div className="cards__image-box">
        {!isLoadedThumb && <ImagePlaceholder />}
        {!!image && <img
          className="cards__image-thumb"
          src={`${REACT_APP_CDN_SERVICE}/icons/${image}`}
          width="140"
          height="140"
          alt={title}
          onLoad={onLoad}
        />}
      </div>
      <div className="cards__content">
        <div className="cards__content__wrapper">
          <h3 className="cards__title">{title}</h3>
          <p className="cards__description">{description}</p>
        </div>
        <ul className="cards__links">
          {links.map(({ name, link }, index) => {
            return (
              <li key={index} className="cards__links__item">
                <Button key={name} name={name} link={link} />
              </li>
            );
          })}
        </ul>
      </div>
    </li>
  );
};

export default Card;
