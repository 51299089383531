import React from 'react';
import './index.scss';

export interface Props {
    name: string
    link: string
}

const Button = (props: Props) => {

    const {name, link} = props

    return (
        <button className="btn">
            <a href={link} className="btn__link" rel="noopener noreferrer" target="_blank">
                {name}
            </a>
        </button>
    )
}

export default Button
