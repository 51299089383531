import React, { useEffect, useState } from "react";
import { UserComponent } from "@megapolis/react-auth";
import { REACT_APP_CDN_SERVICE } from "env";
import Logo from "./logo/index";
import Main from "./main/index";
import ScrollButton from "../../components/scroll-button/ScrollButton";
import "./index.scss";

const MainPage = () => {
  const [showScrollBtn, setShowScrollBtn] = useState(false);

  const cardsRef = React.createRef<HTMLDivElement>();

  const [cards, setCards] = React.useState<CardInfo[] | Error | null>(null);

  useEffect(() => {
    fetch(`${REACT_APP_CDN_SERVICE}/content.json`)
      .then((response) => response.json())
      .then((cards) => setCards(cards))
      .catch((error) => setCards(error));
  }, []);

  return (
    <div className="main-page">
      <div className="tmpl">
        <div className="tmpl__container">
          <div className="tmpl-head">
            <div className="tmpl-head__container">
              <div className="tmpl-nav">
                <Logo />
              </div>
              <div className="tmpl-entry"></div>
              <div className="tmpl-user">
                <UserComponent size={42} />
              </div>
            </div>
          </div>
          <div className="tmpl-body">
            <div className="tmpl-body__container">
              <div className="tmpl-body__main">
                <div className="tmpl-body__content">
                  <div className="tmpl-main">
                    {cards === null && <div className="tmpl-body__loading">загрузка ...</div>}
                    {cards instanceof Error &&
                      "Не удалось загрузить список систем"}
                    {Array.isArray(cards) && (
                      <Main
                        cards={cards}
                        cardsRef={cardsRef}
                        setShowScrollBtn={setShowScrollBtn}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollButton cardsRef={cardsRef} showScrollBtn={showScrollBtn} />
    </div>
  );
};

export default MainPage;
