// AUTH
export const REACT_APP_PUBLIC_URL: string = String(
  (window as any)?.env?.REACT_APP_PUBLIC_URL ??
    process.env.REACT_APP_PUBLIC_URL ??
    ""
);
export const REACT_APP_AUTH_URL: string = String(
  (window as any)?.env?.REACT_APP_AUTH_URL ?? process.env.REACT_APP_AUTH_URL
);
export const REACT_APP_AUTH_SCOPE: string = String(
  (window as any)?.env?.REACT_APP_AUTH_SCOPE ?? process.env.REACT_APP_AUTH_SCOPE
);
export const REACT_APP_AUTH_CLIENT_ID: string = String(
  (window as any)?.env?.REACT_APP_AUTH_CLIENT_ID ??
    process.env.REACT_APP_AUTH_CLIENT_ID
);

// SERVICES

export const REACT_APP_CDN_SERVICE: string = String(
  (window as any)?.env?.REACT_APP_CDN_SERVICE ??
    process.env.REACT_APP_CDN_SERVICE
);

// MISC

export const REACT_APP_FAVICON_NAME: string = String(
  (window as any)?.env?.REACT_APP_FAVICON_NAME ??
    process.env.REACT_APP_FAVICON_NAME
);
