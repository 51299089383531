import React from 'react'

const ImagePlaceholder: React.FC = () => {
    return (
        <svg className="cards__item__image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140">
            <path d="M70,11.95A58.09,58.09,0,1,0,128.08,70,58.15,58.15,0,0,0,70,11.95ZM28,86.57,60,54.68h8.95l-32,31.89Zm30.55,0H49.59l32-31.89h8.95Zm21,.39H70.33l32.8-32.68h9.17Z" fill="#eceef4" />
            <path d="M78.28.49A70,70,0,0,0,0,69.77H0A70,70,0,0,0,69.77,140h0A70,70,0,0,0,140,70.24h0A70,70,0,0,0,78.28.49ZM70,132.12A62.09,62.09,0,1,1,132.08,70,62.16,62.16,0,0,1,70,132.12Z" fill="#eceef4" />
        </svg>
    )
}

export default ImagePlaceholder;
