import React from "react";
import { Authentication } from "@megapolis/react-auth";
import * as env from "env";
import MainPage from "./main";
import "./App.scss";

function App() {
  return (
    <Authentication
      authority={env.REACT_APP_AUTH_URL}
      client_id={env.REACT_APP_AUTH_CLIENT_ID}
      scope={env.REACT_APP_AUTH_SCOPE}
      publicUrl={env.REACT_APP_PUBLIC_URL ?? ""}
    >
      <div className="app system-list-web">
        <MainPage />
      </div>
    </Authentication>
  );
}

export default App;
